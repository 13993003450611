<template>
  <v-card class="d-flex flex-column charcoal noscroll-x">
    <BaseCloseDialogHeader :heading="'Re-Order ' + type" @cancel="cancel" />
    <v-card-subtitle class="body-1 silver--text px-2 py-4">
      Click the {{ name }} below in the order you want them displayed. Click
      <span class="progressActive--text">Done</span> when finished.
    </v-card-subtitle>
    <v-card class="px-2 mx-3 transparent charcoalTile" tile flat rounded>
      <v-list dense class="transparent">
        <v-subheader class="title paper--text">{{ type }}</v-subheader>
        <v-list-item-group v-model="item" color="progressActive">
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            @click="setSort(item)"
          >
            <v-list-item-icon class="mr-2">
              <v-icon color="progressActive" v-text="item.sort"></v-icon>
            </v-list-item-icon>
            <v-list-item-content class="pl-0">
              <v-list-item-title
                class="paper--text pl-0 ml-0"
                v-text="item.name"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
    <v-spacer />
    <v-card-actions class="px-0">
      <BaseActionButton
        icon="mdi-restart"
        label="Restart"
        plain
        color="silver"
        @clickedThis="restart"
      />
      <v-spacer />

      <BaseActionButton
        icon="mdi-checkbox-outline"
        :disabled="clicks != items.length"
        label="Done"
        text
        color="paper"
        @clickedThis="done"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      clicks: 0,
      item: ''
    }
  },
  beforeMount() {
    this.clicks = 0
  },
  props: {
    items: {
      type: Array
    },
    name: {
      type: String,
      default: 'Item'
    },
    type: {
      type: String,
      default: 'Exercises'
    }
  },
  methods: {
    done() {
      this.$emit('clickedDone')
      this.clicks = 0
    },
    clear() {
      this.items.forEach(el => {
        el.sort = null
        el.clicked = false
      })
    },
    restart() {
      this.clicks = 0
      this.clear()
    },
    setSort(item) {
      let size = this.items.length
      if (this.clicks == size) {
        return
      } else {
        if (this.clicks == 0) {
          this.clear()
        }
        if (!item.clicked) {
          this.clicks = this.clicks + 1
          item.sort = this.clicks
        }
        item.clicked = true
      }
    },
    cancel() {
      this.$emit('clickedCancel')
      this.clicks = 0
    }
  }
}
</script>
